import React from "react"

import { PageLayout } from "../components/Layout"

import TextSectionBackground from "../components/TextSectionBackground"

const NotFoundPage = () => (
  <PageLayout>
    <TextSectionBackground style={{minHeight:"calc(100vh - 606px)"}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </TextSectionBackground>
    
  </PageLayout>
)

export default NotFoundPage
